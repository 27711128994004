export const PharmaSearchConfig = [
  {
    label: 'BU',
    prop: 'bu',
    options: []
  },
  {
    label: 'Franchise',
    prop: 'franchise',
    options: []
  },
  {
    label: 'PromotionGrid',
    prop: 'promotiongrid',
    options: []
  },
  {
    label: 'AH',
    prop: 'ah',
    options: []
  },
  {
    label: 'RM',
    prop: 'rm',
    options: []
  },
  {
    label: 'DM',
    prop: 'dm',
    options: []
  },
  {
    label: 'MR',
    prop: 'mr',
    options: []
  },
  {
    label: 'Post Level',
    prop: 'postlevel',
    options: []
  },
  {
    label: 'Assistant',
    prop: 'assistant',
    options: ''
  }
];

export const OncoSearchConfig = [
  {
    label: 'BU',
    prop: 'bu',
    options: []
  },
  {
    label: 'PromotionGrid',
    prop: 'promotiongrid',
    options: []
  },
  {
    label: 'RD',
    prop: 'rd',
    options: []
  },
  {
    label: 'DM',
    prop: 'dm',
    options: []
  },
  {
    label: 'MR',
    prop: 'mr',
    options: []
  },
  {
    label: 'Post Level',
    prop: 'postlevel',
    options: []
  },
  {
    label: 'Assistant',
    prop: 'assistant',
    options: ''
  }
];

// 穿梭框的左边部分，需要换为树形结构图。
export const hrCoreConfig = [
  {
    label: 'BU',
    prop: 'bu',
    options: []
  },
  {
    label: 'PromotionGrid',
    prop: 'promotiongrid',
    options: []
  },
  {
    label: 'RD',
    prop: 'rd',
    options: []
  },
  {
    label: 'DM',
    prop: 'dm',
    options: []
  },
  {
    label: 'MR',
    prop: 'mr',
    options: []
  },
  {
    label: 'Post Level',
    prop: 'postlevel',
    options: []
  },
  {
    label: 'Assistant',
    prop: 'assistant',
    options: ''
  }
];


// 穿梭框的左边部分，需要换为树形结构图。
export const hrCoreConfigNew = [
  {
    label: 'Employee Group',
    prop: 'employeeGroups',
    options: []
  },
  {
    label: 'Sub Group',
    prop: 'employeeSubGroups',
    options: []
  },
  {
    label: 'Division',
    prop: 'divisions',
    options: []
  },

  {
    label: 'Site Code',
    prop: 'siteCodes',
    options: []
  },

];

export const rulesConfig = {
  groupName: [{ required: true, message: '请输入分组名称', trigger: 'blur' }],
  // file: [{ required: true, message: '请上传文件', trigger: ['blur', 'change'] }]
};
