export enum TYPESTATU {//权限 ->permissions.Vue
  PHARMA = 'Pharma',
  HRCORE = 'HrCore', // HR CORE
  NONFF = 'NonFf' // NONFF
}

export enum DivisionOrg {
  PHARMA = 'pharmaOrg',
  HRCORE = 'hrCoreOrg', // HR CORE
  NONFF = 'nonFfOrg' // NONFF
}

export enum UserGroupType {
  PHARMA = 'pharma',
  HRCORE = 'hrcore',
  UPLOAD = 'upload',
  NONFF = 'nonff',
}

export enum UserGroupConditionType {
  POST = 'post',
  DEPT = 'dept',
  USER = 'user',
  GROUP = 'group',
  CONDITION= 'condition'
}
