








































































































































































































































































import { Component, Model, Prop, Vue, Watch } from 'vue-property-decorator';
import { Inject } from '@cds/common';
import { GroupService } from '@/services/group-service';
import { DivisionOrg, UserGroupType } from '@/views/userGroup/config';
import { DialogService } from '@/services/common/dialog.service';
import ModifyUserGroup from '@/components/UserGroup/ModifyUserGroup.vue';
import { UserGroupService } from '@/services/user-group-service';
import { uuid } from '@/services/utils';
import List from '@/views/simulate/list.vue';
import { cloneDeep, isArray, debounce } from 'lodash';
import UserGroupDetailTable from '@/components/UserGroup/UserGroupDetailTable.vue';
@Component({
  components: { UserGroupDetailTable }
})
export default class UserGroupDetail extends Vue {
  @Inject(UserGroupService) private userGroupService!: UserGroupService;
  @Inject(DialogService) private dialogService!: DialogService;
  @Inject(GroupService) private groupService!: GroupService;
  private userGroupInitUpdateDebounce = debounce(this.userGroupInitUpdate, 500);
  private showUserTabel: boolean = false;
  private userGroupDetailLoading: boolean = false;
  private showOverHeightButton: boolean = false;
  private loading: boolean = false;
  private isFoldCondition: boolean = true;
  private userGroup: any = {};
  private formData: any = {};
  private userList: any[] = [];
  private isContainPharmaCondition: boolean = false;
  private isContainHrCoreCondition: boolean = false;
  private isContainUploadCondition: boolean = false;
  private isContainNonffCondition: boolean = false;
  private pharmaCondition: any = {};
  private nonffCondition: any = {};
  private hrcoreCondition: any = {};
  private uploadUserCondition: any = {}

  @Prop({ required: false })
  private userVoList: any;

  @Model('change', {
    type: Array, default: () => {
    }
  })
  public userGroupInit: any;

  @Watch('userGroupInit', { deep: true })
  public handleUserGroupChange(userGroupInit: any[]) {
    this.userGroupInitUpdateDebounce(userGroupInit)
  }

  private async userGroupInitUpdate(userGroupInit: any): Promise<void> {
    console.log('userGroupInit', userGroupInit, this.userGroup);
    // this.userList = [];
    this.showUserTabel = false;
    this.isFoldCondition = true;
    if (userGroupInit.length === 1) {
      if (this.userGroup.id !== userGroupInit[0].id || this.userGroup.refreshTime !== userGroupInit[0].refreshTime) {
        // this.getUserGroupDetail(userGroupInit.id, userGroupInit.type);
        await this.getUserGroupConditionAndUsers(userGroupInit[0].id);
        this.showOverHeightButton = this.getShowOverHeightButton();
      }
      this.userGroup = userGroupInit[0];
    }
  }

  @Watch('userVoList', { deep: true })
  public handleUserVoListChange(value: any[]) {
    this.userList = value;
    // this.userList = this.userVoList;
  }
  public getShowOverHeightButton() {
    const conditionDom = document.getElementById('user-group-condition');
    if (conditionDom && conditionDom.offsetHeight) {
      console.log('conditionDom', conditionDom.scrollHeight)
      return conditionDom.scrollHeight > 150
    } else {
      return false
    }
  }


  public created() {

  }

  public async mounted(): Promise<void> {
    this.userGroup = cloneDeep(this.userGroupInit)
    if (this.userGroupInit[0] && this.userGroupInit[0].id) {
      await this.getUserGroupConditionAndUsers(this.userGroupInit[0].id);
      this.showOverHeightButton = this.getShowOverHeightButton();
    }
  }

  public async getUserGroupConditionAndUsers(id: any): Promise<any> {
    this.userGroupDetailLoading = true;
    try {
      let groupDetailRes = await this.userGroupService.getHRCoreUsergroupById(id);
      let userListRes = await this.userGroupService.retrieveUserGroupEffectiveUserList([id]) || [];
      console.log(groupDetailRes,'groupDetailRes')
      if (groupDetailRes.conditions) {
        this.initUserGroupCondition(groupDetailRes.conditions);
      }
      if (userListRes.length == 1) {
        this.userList = userListRes[0].userVoList;
      }

    } catch {
      this.userList = [];
    } finally {
      this.userGroupDetailLoading = false;

    }
  }

  private initUserGroupCondition(condition: any) {
    let pharmaCondition: any = {}, hrcoreCondition: any = {}, uploadUserCondition: any = {}, nonffCondition: any = {},
      isContainPharmaCondition: boolean = false, isContainHrCoreCondition: boolean = false, isContainNonffCondition: boolean = false;

    if (isArray(condition.pharma) && condition.pharma.length > 0) {
      let conditionPost: any[] = [], conditionGroup: any = {}, conditionSelectedUsers: any[] = [];
      for (let pharmaCondition of condition.pharma) {
        switch (pharmaCondition.type) {
          case 'post':
            conditionPost.push(pharmaCondition.dept.deptName);
            break;
          case 'group':
            conditionGroup = pharmaCondition.group;
            break;
          case 'user':
            conditionSelectedUsers.push(pharmaCondition.user.userName);
            break;
          default:
            break;
        }

      }
      pharmaCondition.post = conditionPost;
      pharmaCondition.group = conditionGroup;
      pharmaCondition.users = conditionSelectedUsers;
      isContainPharmaCondition = true;
    }
    if (isArray(condition.nonff) && condition.nonff.length > 0) {
      let conditionPost: any[] = [], conditionGroup: any = {}, conditionSelectedUsers: any[] = [];
      for (let nonffCondition of condition.nonff) {
        switch (nonffCondition.type) {
          case 'post':
            conditionPost.push(nonffCondition.dept.deptName);
            break;
          case 'group':
            conditionGroup = nonffCondition.group;
            break;
          case 'user':
            conditionSelectedUsers.push(nonffCondition.user.userName);
            break;
          default:
            break;
        }

      }
      nonffCondition.post = conditionPost;
      nonffCondition.group = conditionGroup;
      nonffCondition.users = conditionSelectedUsers;
      isContainNonffCondition = true;
    }

    if (isArray(condition.hrcore) && condition.hrcore.length > 0) {
      let conditionDept: any[] = [], conditionGroup: any = {}, conditionSelectedUsers: any[] = [];
      for (let hrcoreCondition of condition.hrcore) {
        switch (hrcoreCondition.type) {
          case 'dept':
            conditionDept.push(hrcoreCondition.dept.deptName);
            break;
          case 'condition':
            conditionGroup = hrcoreCondition.hrcoreSearch;
            break;
          case 'user':
            conditionSelectedUsers.push(hrcoreCondition.user.userName);
            break;
          default:
            break;
        }
      }
      hrcoreCondition.dept = conditionDept;
      hrcoreCondition.condition = conditionGroup;
      hrcoreCondition.users = conditionSelectedUsers;
      isContainHrCoreCondition = true;
    }

    this.pharmaCondition = pharmaCondition;
    this.hrcoreCondition = hrcoreCondition;
    this.nonffCondition = nonffCondition;
    // this.uploadUserCondition = uploadUserCondition;
    this.isContainPharmaCondition = isContainPharmaCondition;
    this.isContainHrCoreCondition = isContainHrCoreCondition;
    this.isContainNonffCondition = isContainNonffCondition;

  }

  private async getUserGroupDetail(id: string, type: DivisionOrg) {
    try {
      let res: any = {};
      if (type == DivisionOrg.HRCORE) {
        res = await this.userGroupService.getHRCoreUsergroupById(id);
      } else {
        res = await this.userGroupService.getusergroupById(id);
      }
      this.formData = res;
    } finally {
      // this.formLoading = false;
    }
  }

  private async updateUserGroup(): Promise<void> {
    const key = uuid();
    await this.getUserGroupDetail(this.userGroup.id, this.userGroup.type)
    const res = await this.dialogService.open(
      '用户分组规则',
      ModifyUserGroup,
      {
        config: [
          {}
        ],
        formData: this.formData,
        type: 'hr core',
        key
      },
      {
        height: 'auto', key
      }
    );
    console.log(res);
  }

  private async showDetail(): Promise<void> {
    this.loading = true;
    this.showUserTabel = true;
    let res!: any;
    let params: any[] = [];
    params.push(this.userGroup.id);
    if (this.userGroup.type === DivisionOrg.HRCORE) {
      res = (await this.userGroupService.batchGetUsersForHrCore(params)) || [];
    } else {
      res = (await this.userGroupService.batchGetUsers(params)) || [];
    }
    res.forEach((element: any) => {
      if (element.effectiveNumber === 0) {
        element.effectiveNumber = '-';
      }
    });
    this.userList = res;
    this.loading = false;
  }

  private changeConditionFold() {
    this.isFoldCondition = !this.isFoldCondition;
  }
}
