







































































































import { Component, Model, Prop, Vue, Watch } from 'vue-property-decorator';
import { I18nService } from '@cds/i18n';
import { Inject } from '@cds/common';
import { UserGroupService } from '@/services/user-group-service';
import { cloneDeep, debounce, filter, find, findIndex, forEach, isArray, isString, lowerCase, some } from 'lodash';
import { OncoSearchConfig, PharmaSearchConfig } from '../NovaGroupTransfer/index.config';
import { DepartmentService } from '@/services/department-service';
import { UserGroupConditionType } from '@/views/userGroup/config';

@Component({
})
export default class NovaUserGroupSelector extends Vue {
  public Pharma :any = localStorage.getItem("setup");
  private isShowPostTree: boolean = false;
  // tree
  public postTree: any[] = [];
  public defaultProps = {
    children: 'children',
    label: 'name'
  }
  public defaultCheckedKeys: any[] = [];
  public defaultExpandedKeys: any[] = [];
  public effectMembersCount: number = 0;
  public defaultDisabledDepartmentID: any = [];

  public loading: boolean = false;
  public filterText: string = '';
  public placeholder: string = '搜索用户名、邮箱、员工号、Postline';
  // public quickQueryUserList = debounce(this.queryUser, 800);
  public removedDropdownlist: Dict<boolean> = {};
  public searchConfig: Dict<any> = [];
  private fillIntervalId!: number;
  // 初始化数组
  private showDropSearch: boolean = false; // 搜搜菜单
  // 左侧可选
  private searchResults!: Dict<any[]>;
  private searchForm: any = {};
  private initDataCount!: number; // 通过搜索获取的数组长度 每次搜索后重新赋值
  private isSearch: boolean = true; // 是否搜索的道德flag 默认false

  // search results
  public showDepartmentSearch: boolean = false; // 搜搜菜单
  public searchLoading: boolean = false;
  public deptSearchList: any = [];
  public memberSearchList: any = [];
  public deptCheckList: any = [];
  public memberCheckList: any = [];

  private userGroupCondition: any = [];

  @Model('change', { type: Array, default: () => [] })
  private rightInitData!: any[];

  @Prop({ required: true })
  private userGroupRoles!: Dict<any>;

  @Prop({ required: true })
  private type!: string;

  @Inject(UserGroupService)
  private userGroupService!: UserGroupService;

  // i8n
  @Inject(I18nService)
  private i18nSvc!: I18nService;

  @Inject(DepartmentService)
  private departmentService!: DepartmentService;


  @Watch('rightInitData', { deep: true })
  public async handleInitUserGroupCondition(value: Dict<any>): Promise<void> {
    console.log("watch pharma rightInitData", value)
    this.loading = true;
    this.userGroupCondition = cloneDeep(value);
    await this.getSearchOption({});
    this.initUserGroupConditions();
    this.disabledChildrenOfData(this.postTree, false);
    this.getDefaultCheckedKeys(value, this.postTree);
    this.postTree = cloneDeep(this.postTree);
    if (this.showDropSearch) {
      this.initCheckedListBySearchText(this.deptSearchList, this.memberSearchList, value)
      this.updateCheckBoxDisableValue();
    }
    this.loading = false;
    // await this.initEmployeeData();
  }

  // @Watch('userGroupRoles')
  // public async handleinitUserGroupRoles(value: Dict<any>): Promise<void> {
  //   this.showDropSearch = true;
  //   // this.searchForm = {};
  //   await this.getSearchOption({});
  //   this.leftSearch = '';
  //   this.searchForm = value;
  //   this.removedDropdownlist = {};
  //   forEach(this.searchForm, (item, key) => {
  //     this.removedDropdownlist[key] = isArray(item) && item.length > 0;
  //   });
  // }

  get i18n(): Dict<any> {
    return this.i18nSvc.i18nData;
  }

  get isComfirmButtonDisabled(): boolean {
    let isdisabled: boolean = true;
    // if( this.searchForm.assistant){
    //   this.searchForm.assistant =  this.searchForm.assistant.split('')
    // }
    forEach(this.searchForm, (item, key) => {
      if (key == 'assistant') {
        if (item) {
          isdisabled = false;
        }
      } else if (isArray(item) && item.length > 0) {
        isdisabled = false;
      }
    });
    return isdisabled;
  }

  public async mounted(): Promise<void> {
    this.loading = true;
    console.log(this.rightInitData,'rightInitData')
    if (this.rightInitData) {
      this.userGroupCondition = cloneDeep(this.rightInitData);
    }
    await this.getSearchOption({});
    this.initUserGroupConditions();
    await this.initEmployeeData();
    this.loading = false;
  }

  public initUserGroupConditions() {
    console.log(this.userGroupCondition,'pharma页面的下拉赋值初始化所有值')
    let groupCondition = isArray(this.userGroupCondition) ? this.userGroupCondition.filter((condition: any) => condition.type == UserGroupConditionType.GROUP) : [];
    console.log('pharma页面的searchForm', this.searchForm)
    if (groupCondition.length == 1) {
      console.log('pharma页面的下拉赋值', groupCondition)
      this.searchForm = groupCondition[0].group;
      forEach(this.searchForm, (item, key) => {

        this.removedDropdownlist[key] = isArray(item) && item.length > 0;
      });
    } else {
      this.resetSearch();
    }
    // this.getDefaultCheckedKeys(this.userGroupCondition, this.postTree);

  }

  private async initEmployeeData() {
    let res = [];
    console.log('248', this.type)
    if (this.type == this.Pharma) {
      res = (await this.departmentService.getPharmaTree()) || [];
    }
    if (res && isArray(res)) {
      this.postTree = res;
    }

    // 初始化选中的节点
    // this.getDefaultExpandedKeys(this.departmentTree);
    this.getDefaultCheckedKeys(this.userGroupCondition, this.postTree);
    this.loading = false;
  }

  public clearSearch(): void {
    if (!this.filterText) {
      this.showDropSearch = false
      this.getDefaultCheckedKeys(this.userGroupCondition, this.postTree);
    }
  }

  public getLabel(
    options: Dict<any>[],
    val: string,
    key: string,
    removeUnexists?: boolean
  ): string | undefined {
    const target = find(options, (e) => e.value === val);
    if (!target) {
      const index = findIndex(this.searchForm[key], (e) => e === val);
      if (index > -1) {
        if (removeUnexists) {
          this.searchForm[key].splice(index, 1);
          console.log('2', this.searchForm)
        }
        if (this.searchForm[key].length === 0) {
          this.removedDropdownlist[key] = false;
        }
      }
      return;
    } else {
      return target.label || target.value;
    }
  }

  // 搜索dropdown
  public dropdownSearch(): void {
    if (this.filterText) {
      this.showDropSearch = true
      this.deptCheckList = []
      this.memberCheckList = []
      this.queryUserAndDepartByText()
    } else {
      this.showDropSearch = false
      this.getDefaultCheckedKeys(this.userGroupCondition, this.postTree)
    }
  }

  // 搜索确定
  public async confirmSearch(): Promise<void> {
    this.loading = true;
    let searchParm = cloneDeep(this.searchForm);
    console.log('3', this.searchForm)
    searchParm.division = this.type;
    let searchRes = (await this.userGroupService.retrieveUserGroupDivisionUserCount(searchParm));

    let newGroupCondition = Object.assign({}, {
      type: 'group',
      effectUserNumbers: searchRes && searchRes.count ? searchRes.count : 0,
      group: cloneDeep(this.searchForm)
    });
    let groupIndex = this.userGroupCondition.findIndex((item: any) => item.type === UserGroupConditionType.GROUP);
    console.log(groupIndex,'groupIndex')
    console.log(newGroupCondition,'newGroupCondition')
    if (groupIndex === -1) {
      this.userGroupCondition.push(newGroupCondition);
    } else {
      this.userGroupCondition.splice(groupIndex, 1);
      this.userGroupCondition.push(newGroupCondition);
    }
    console.log(this.userGroupCondition,'this.userGroupCondition')
    this.$notify({
      title: '成功',
      message: '筛选添加成功',
      type: 'success'
    });
    this.loading = false;
    this.$emit('change', this.userGroupCondition);
  }

  public resetSearch(): void {
    forEach(this.searchForm, (item, key) => {
      if (key == 'assistant') {
        this.searchForm[key] = '';
      } else
        if (key !== 'division') {
          this.searchForm[key] = [];
        }
    });
    this.removedDropdownlist = {};
    this.getSearchOption();
  }

  public handleCloseTag(index: number, propKey: string): void {
    if (
      isArray(this.searchForm[propKey]) &&
      this.searchForm[propKey].length > 0
    ) {
      this.searchForm[propKey].splice(index, 1);
      this.handleNoTag(this.searchForm[propKey], propKey);
    }
  }

  public handleOptionsChange(isOpen: boolean, propKey: string): void {
    // if (isOpen) {
    //   const target = find(this.searchConfig, (item) => item.prop === propKey);
    //   target.options = this.searchResults[propKey];
    // }
    console.log(!isOpen &&
      isArray(this.searchForm[propKey]) &&
      this.searchForm[propKey].length > 0
    )
    console.log(this.searchForm)
    if (
      !isOpen &&
      isArray(this.searchForm[propKey]) &&
      this.searchForm[propKey].length > 0
    ) {
      this.removedDropdownlist[propKey] = true;
      this.getSearchOption();
    }
  }

  public handleNoTag(val: string[], propKey: string): void {
    if (val[0] == 'Y' || val[0] == 'N') {
      forEach(this.searchForm, (item, key) => {
        if (key == 'assistant') {
          this.searchForm[key] = val[0];
        } else
          if (key !== 'division') {
            this.searchForm[key] = [];
          }
      });
    }else{
      forEach(this.searchForm, (item, key) => {
        if (key == 'assistant') {
          this.searchForm[key] = '';
        }
      });
    }
    if (isArray(val) && val.length === 0) {
      this.removedDropdownlist[propKey] = false;
      this.getSearchOption();
    }
  }
  public destroy(): void {
    clearInterval(this.fillIntervalId);
  }

  // 获取筛选下拉
  private async getSearchOption(val?: Dict<any>): Promise<void> {
    this.loading = true;
    var data_: any = '';
    console.log('val', val)
    console.log('this.searchForm', this.searchForm)
    // this.searchForm.assistant = this.searchForm.assistant.toString();
    this.searchConfig =
      this.type === this.Pharma ? PharmaSearchConfig : OncoSearchConfig;
    this.searchResults = await this.userGroupService.postcondition(
      'pharma',
      val ? val : this.searchForm
    );
    console.log(this.searchResults ,'pharma页面所有的下拉')
    // this.searchResults = await this.userGroupService.postcondition( 'pharma',data_);
    // const keys = Object.keys(res);
    // this.searchConfig.forEach((item: any) => {
    //   item.options = this.searchResults[item.prop];
    // });
    this.startFillSearchConf();
    this.loading = false;
  }

  private startFillSearchConf(): void {
    clearInterval(this.fillIntervalId);
    forEach(this.searchConfig, (item: any) => {
      item.options = [];
    });
    let index = 0;
    this.pushSearchResults(index);
    index += 50;
    this.fillIntervalId = setInterval(() => {
      this.pushSearchResults(index);
      // forEach(that.searchConfig, (item: any) => {
      //   if ((that.searchResults[item.prop] as any[]).length < index) {
      //     item.options.push(...(that.searchResults[item.prop] as any[]).slice(index, index + 50));
      //   }
      // });
      index += 50;
    }, 500);
  }

  private pushSearchResults(index: number): void {
    for (const item of this.searchConfig as any) {
      if ((this.searchResults[item.prop] as any[]).length > index) {
        item.options.push(
          ...(this.searchResults[item.prop] as any[]).slice(index, index + 50)
        );
        // forEach(this.searchForm[item.prop], (val) => {
        //   if (some(item.options, (op) => op.value === val)) {
        //     this.getLabel(item.options, val, item.prop, true);
        //   }
        // });
      } else {
        forEach(this.searchForm[item.prop], (val) => {
          // if (some(item.options, (op) => op.value === val)) {
          this.getLabel(item.options, val, item.prop, true);
          // }
        });
      }
    }
  }

  public changeSelectorStyle() {
    this.isShowPostTree = !this.isShowPostTree;
  }
  // control tree data
  public async handleCheckChange(data: any, treeStatus: any) {
    if (treeStatus.checkedKeys.includes(data.dept_id)) {
      if (this.defaultCheckedKeys.findIndex((item: any) => item === data.dept_id) === -1) {
        this.defaultCheckedKeys.push(data.dept_id)
      }
      if (data.children && data.children.length > 0) {
        // 1. 父节点的所有子节点disabled,
        await this.disabledChildrenOfData(data.children, true)
        // 2. 判断是否在hrCoreData, 如果在，需要从hrCoreData中移除，并且取消该节点的选中
        await this.removeCheckedChildrenOfData(data.children)
        // 3. 计算父节点的所有子节点个数，即生效人数
        await this.getEffectUserNumbers(data)
        let effectUserNumber = this.effectMembersCount
        this.effectMembersCount = 0
        // 3. 并入hrCoreData
        this.userGroupCondition.push(Object.assign({}, {
          dept: { deptCode: data.dept_id, deptName: data.dept_name },
          type: 'post',
          effectUserNumbers: effectUserNumber
        }));
      } else {
        // 没有子节点的节点直接并入hrCoreData
        await this.getEffectUserNumbers(data)
        let effectUserNumber = this.effectMembersCount
        this.userGroupCondition.push(Object.assign({}, {
          dept: { deptCode: data.dept_id, deptName: data.dept_name },
          type: 'post',
          effectUserNumbers: effectUserNumber
        }));
        this.effectMembersCount = 0
      }
    } else {
      if (this.defaultCheckedKeys.findIndex((item: any) => item === data.dept_id) !== -1) {
        this.defaultCheckedKeys.splice(this.defaultCheckedKeys.findIndex((item: any) => item === data.dept_id), 1)
      }
      if (this.userGroupCondition.findIndex((item: any) => item.type == UserGroupConditionType.POST && item.dept.deptCode === data.dept_id) !== -1) {
        // 1. 遍历hrCoreData，从hrCoreData中把取消选中的节点删除
        this.userGroupCondition.splice(this.userGroupCondition.findIndex((item: any) => item.type == UserGroupConditionType.POST && item.dept.deptCode === data.dept_id), 1);
        // 2. 判断取消选中的节点是否有子节点，如果有子节点的话，需要修改子节点的disabled属性
        if (data.children && isArray(data.children) && data.children.length > 0) {
          await this.disabledChildrenOfData(data.children, false)
        }
      }
    }
    this.$emit('change', this.userGroupCondition);
  }
  // 获取有效员工数
  public async getEffectUserNumbers(data: any) {
    let param = { postName: data.dept_id };
    if (this.type == this.Pharma) {
      let result = await this.departmentService.getPharmaPositionEffectiveCount(param)
      this.effectMembersCount = result
    } else {
      let result = await this.departmentService.getOncoPositionEffectiveCount(param)
      this.effectMembersCount = result
    }
    // let result = await this.departmentService.getMembersOfDepart({ deptId: data.id, divisionCode: ''})
    // let result = await this.departmentService.getDepartMembersByID(data.dept_id);
    // this.effectMembersCount = isArray(result) && result.length ? result.length : 0;
    // console.log(result)
  }

  public getDefaultCheckedKeys(userGroupCondition: any, departmentTree: any) {
    let newDefaultCheckedKeys: any = [], newDefaultExpandedKeys: any = [];
    if (departmentTree && isArray(departmentTree) && departmentTree.length > 0) {
      departmentTree.forEach((tree: any) => {
        if (tree.children && isArray(tree.children) && tree.children.length > 0) {
          tree.children.forEach((childTree: any) => {
            newDefaultExpandedKeys.push(childTree.dept_id);
          });
        } else {
          newDefaultExpandedKeys.push(tree.dept_id);
        }
      });
    }
    if (userGroupCondition && isArray(userGroupCondition) && userGroupCondition.length > 0) {
      userGroupCondition.forEach((item: any) => {
        if (item.type === UserGroupConditionType.POST) {
          if (newDefaultCheckedKeys.findIndex((key: any) => item.type == UserGroupConditionType.POST && key === item.dept.deptCode) === -1) {
            newDefaultCheckedKeys.push(item.dept.deptCode);
            newDefaultExpandedKeys.push(item.dept.deptCode);
          }
          // 判断是否有子节点，并且把子节点disabled
          this.getChildrenCheckedKeys(item.dept.deptCode, departmentTree);
        }
      });
    }
    this.defaultCheckedKeys = newDefaultCheckedKeys;
    this.defaultExpandedKeys = newDefaultExpandedKeys;
  }

  public getChildrenCheckedKeys(deptId: any, itemData: any) {
    itemData.forEach((item: any) => {
      if (item.dept_id === deptId) {
        let children = item.children;
        if (children && isArray(children) && children.length > 0) {
          children.forEach((child: any) => {
            this.$set(child, 'disabled', true);
            let index = this.defaultDisabledDepartmentID.indexOf(child.dept_id);
            if (index == -1) {
              this.defaultDisabledDepartmentID.push(child.dept_id);
            }
            if (child.children && isArray(child.children) && child.children.length > 0) {
              this.getChildrenCheckedKeysOfNode(child.children);
            }
          });
        }
      } else {
        let children = item.children;
        if (children && isArray(children) && children.length > 0) {
          this.getChildrenCheckedKeys(deptId, children);
        }
      }
    });
  }

  public getChildrenCheckedKeysOfNode(children: any) {
    children.forEach((child: any) => {
      this.$set(child, 'disabled', true)
      let index = this.defaultDisabledDepartmentID.indexOf(child.dept_id);
      if (index == -1) {
        this.defaultDisabledDepartmentID.push(child.dept_id);
      }
      if (child.children && isArray(child.children) && child.children.length > 0) {
        this.getChildrenCheckedKeysOfNode(child.children)
      }
    })

  }

  public disabledChildrenOfData(children: any, flag: boolean) {
    if (isArray(children) && children.length !== 0) {
      children.forEach((item: any) => {
        this.$set(item, 'disabled', flag);
        if (flag) {
          let index = this.defaultDisabledDepartmentID.indexOf(item.dept_id);
          if (index == -1) {
            this.defaultDisabledDepartmentID.push(item.dept_id);
          }
        } else {
          let index = this.defaultDisabledDepartmentID.indexOf(item.dept_id);
          if (index !== -1) {
            this.defaultDisabledDepartmentID.splice(index, 1);
          }
        }
        if (item.children && isArray(item.children) && item.children.length > 0) {
          this.disabledChildrenOfData(item.children, flag)
        } else {
          this.disabledChildrenOfData([], flag)
        }
      })
    }
  }

  public removeCheckedChildrenOfData(children: any) {
    if (isArray(children) && children.length !== 0) {
      children.forEach((child: any) => {
        let index = this.userGroupCondition.findIndex((item: any) => item.type == UserGroupConditionType.POST && item.dept.deptCode === child.dept_id);
        if (index !== -1) {
          this.userGroupCondition.splice(index, 1)
          if (this.defaultCheckedKeys.findIndex((item: any) => item === child.dept_id) !== -1) {
            this.defaultCheckedKeys.splice(this.defaultCheckedKeys.findIndex((item: any) => item === child.dept_id), 1)
          }
          (this.$refs as any).tree2.setChecked(child, false, true)
        } else {
          if (child.children && isArray(child.children) && child.children.length > 0) {
            this.removeCheckedChildrenOfData(child.children)
          }
        }
      })
    }
  }

  public changeMemberChecked(memberItem: any) {
    // 判断选中的memberItem是否在checkedlist中，如果不在，则是取消，需要从hrCoreData中删除该条数据；如果在，则加入hrCoreData
    let index = this.memberCheckList.findIndex((item: any) => item === memberItem.userId)
    if (index !== -1) {
      this.userGroupCondition.push(Object.assign({}, memberItem, {
        type: 'user',
        effectUserNumbers: 1,
        user: { userId: memberItem.userId, userName: memberItem.fullName }
      }));
    } else {
      this.userGroupCondition.splice(this.userGroupCondition.findIndex((item: any) => item.type == UserGroupConditionType.USER && item.user.userId === memberItem.userId), 1)
    }
    this.$emit('change', this.userGroupCondition);
  }

  public async changeDeptChecked(dept: any) {
    this.searchLoading = true;
    let index = this.deptCheckList.findIndex((item: any) => item === dept.deptCode);
    if (index !== -1) {
      let result: any;
      let param = { postName: dept.deptCode };
      if (this.type == this.Pharma) {
        result = await this.departmentService.getPharmaPositionEffectiveCount(param)
      } else {
        result = await this.departmentService.getOncoPositionEffectiveCount(param)
      }
      console.log(result);
      let effectUserNumbersCount = result ? result : 0;
      this.userGroupCondition.push(Object.assign({}, {
        dept: { deptCode: dept.deptCode, deptName: dept.deptName },
        type: 'post',
        effectUserNumbers: effectUserNumbersCount
      }));
      this.findAndCheckCurrentNode(dept.deptCode, this.postTree);
      this.updateCheckBoxDisableValue();
      this.searchLoading = false;

    } else {
      this.userGroupCondition.splice(this.userGroupCondition.findIndex((item: any) => item.type == UserGroupConditionType.POST && item.dept.deptCode === dept.deptCode), 1);
      this.findAndDeleteCurrentNode(dept.deptCode, this.postTree);
      this.updateCheckBoxDisableValue();
      this.searchLoading = false;

    }
    this.$emit('change', this.userGroupCondition);

  }

  private findAndDeleteCurrentNode(deptCode: any, children: any) {
    if (children && isArray(children) && children.length !== 0) {
      children.forEach((item: any) => {
        if (item.dept_id == deptCode) {
          if (!this.showDropSearch) {
            (this.$refs as any).tree2.setChecked(deptCode, false, true);
          }
          this.disabledChildrenOfData(item.children, false);
        } else {
          this.findAndDeleteCurrentNode(deptCode, item.children);
        }
      });
    }
  }

  private findAndCheckCurrentNode(deptCode: any, children: any) {
    if (children && isArray(children) && children.length !== 0) {
      children.forEach((item: any) => {
        if (item.dept_id == deptCode) {
          if (!this.showDropSearch) {
            (this.$refs as any).tree2.setChecked(deptCode, true, true);
          }
          this.removeCheckedChildrenOfData(item.children)
          this.disabledChildrenOfData(item.children, true);
        } else {
          this.findAndCheckCurrentNode(deptCode, item.children);
        }
      });
    }
  }

  private async queryUserAndDepartByText(): Promise<void> {
    let result: any = {};

    if (this.type == this.Pharma) {
      result = await this.departmentService.searchPharmaDepartAndUserByKeywords({ keyword: this.filterText });
    } else {
      result = await this.departmentService.searchOncoDepartAndUserByKeywords({ keyword: this.filterText });
    }
    //result为部门输出表deptOutputList和用户输出表userOutputList
    console.log(result);
    if (result.deptOutPutList && isArray(result.deptOutPutList)) {
      this.deptSearchList = result.deptOutPutList;
    }
    if (result.userOutputList && isArray(result.userOutputList)) {
      this.memberSearchList = result.userOutputList;
    }

    // 2. 根据userGroupCondition和list来初始化选中的节点，userGroupCondition中type=dept的需要在deptSearchList中匹配，type=user的在memberSearchList中匹配
    this.initCheckedListBySearchText(this.deptSearchList, this.memberSearchList, this.userGroupCondition)
    this.updateCheckBoxDisableValue();
  }

  public initCheckedListBySearchText(deptList: any, memberList: any, userGroupCondition: any) {
    let deptCheckList: any[] = [], memberCheckList: any[] = [];
    userGroupCondition.forEach((item: any) => {
      if (item.type === UserGroupConditionType.POST) {
        let index = deptList.findIndex((dept: any) => dept.deptCode === item.dept.deptCode);
        if (index !== -1) {
          deptCheckList.push(item.dept.deptCode);
        }
      } else if (item.type === UserGroupConditionType.USER) {
        let index = memberList.findIndex((member: any) => member.userId === item.user.userId);
        if (index !== -1) {
          memberCheckList.push(item.user.userId);
        }
      }
    });
    this.deptCheckList = deptCheckList;
    this.memberCheckList = memberCheckList;
  }

  public updateCheckBoxDisableValue() {
    this.deptSearchList.forEach((item: any) => {
      let index = this.defaultDisabledDepartmentID.indexOf(item.deptCode);
      if (index !== -1) {
        this.$set(item, 'disabled', true);
      } else {
        this.$set(item, 'disabled', false);
      }
    });
  }


}
