































































































































import { Component, Model, Vue, Watch } from 'vue-property-decorator';
import { Inject } from '@cds/common';
import { UserGroupService } from '@/services/user-group-service';
import { DepartmentService } from '@/services/department-service';
import { I18nService } from '@cds/i18n';
import { cloneDeep, find, findIndex, forEach, isArray } from 'lodash';
import { UserGroupConditionType } from '@/views/userGroup/config';
import { hrCoreConfigNew, OncoSearchConfig, PharmaSearchConfig } from '../NovaGroupTransfer/index.config';

@Component({
  components: {}
})
export default class HrCoreUserGroupSelector extends Vue {
  public Pharma :any = localStorage.getItem("setup");
  private type: any = 'HrCore';
  public loading: boolean = false;
  public searchLoading: boolean = false;
  public placeholder: string = '搜索部门、员工号、员工姓名、邮箱';
  private isShowPostTree: boolean = false;
  private searchForm: any = {};
  public searchConfig: Dict<any> = [];
  private userGroupCondition: any = [];
  private fillIntervalId!: number;
  private searchResults!: Dict<any[]>;
  public removedDropdownlist: Dict<boolean> = {};
  // =================== HR CORE ORG ======================
  public departmentTree: any = [];
  public defaultProps = {
    children: 'children',
    label: 'name'
  };
  public defaultCheckedKeys: any = [];
  public defaultExpandedKeys: any = [];
  public defaultDisabledDepartmentID: any = [];
  public effectMemebersCount: any = 0;
  public dialogTableVisible: boolean = false;
  public currentDepartment: string = '';
  public currentDepartMembers: any = [];
  public filterText: string = '';

  // search results
  public showDropSearch: boolean = false; // 搜搜菜单
  public deptSearchList: any = [];
  public memberSearchList: any = [];
  public deptCheckList: any = [];
  public memberCheckList: any = [];

  // Results
  private hrCoreData: any = [];

  @Model('changeHrCoreData', { type: Array, default: () => [] })
  private hrCoreDataInit!: any;

  @Inject(UserGroupService)
  private userGroupService!: UserGroupService;

  @Inject(DepartmentService)
  private departmentService!: DepartmentService;

  // i8n
  @Inject(I18nService)
  private i18nSvc!: I18nService;

  @Watch('hrCoreDataInit', { deep: true })
  public handleInitHrCoreData(value: Dict<any>): void {
    console.log('watch hrCoreDataInit', value);
    this.hrCoreData = cloneDeep(value);
    this.getSearchOption();
    this.initUserGroupConditions();
    this.disabledChildrenOfData(this.departmentTree, false);
    this.getDefaultCheckedKeys(value, this.departmentTree);
    this.departmentTree = cloneDeep(this.departmentTree);
    if (this.showDropSearch) {
      this.initCheckedListBySearchText(this.deptSearchList, this.memberSearchList, value);
      this.updateCheckBoxDisableValue();
    }
  }

  get i18n(): Dict<any> {
    return this.i18nSvc.i18nData;
  }

  get isComfirmButtonDisabled(): boolean {
    let isdisabled: boolean = true;
    // if( this.searchForm.assistant){
    //   this.searchForm.assistant =  this.searchForm.assistant.split('')
    // }
    forEach(this.searchForm, (item, key) => {
      if (key == 'assistant') {
        if (item) {
          isdisabled = false;
        }
      } else if (item && item.length > 0) {
        isdisabled = false;
      }
    });

    return isdisabled;
  }

  public clearSearch(): void {
    if (!this.filterText) {
      this.showDropSearch = false;
      this.getDefaultCheckedKeys(this.hrCoreData, this.departmentTree);
    }
  }

  // 搜索dropdown
  public dropdownSearch(): void {
    if (this.filterText) {
      this.showDropSearch = true;
      this.deptCheckList = [];
      this.memberCheckList = [];
      this.queryUserAndDepartByText();
    } else {
      this.showDropSearch = false;
      this.getDefaultCheckedKeys(this.hrCoreData, this.departmentTree);
    }
  }

  public created() {
    // console.log('created', this.hrCoreDataInit);
  }

  public async mounted(): Promise<void> {
    this.loading = true;
    console.log(this.hrCoreDataInit,'this.hrCoreDataInit')
    this.hrCoreData = cloneDeep(this.hrCoreDataInit);
    await this.getSearchOption({});
    this.initUserGroupConditions();
    await this.initEmployeeData();
    this.loading = false;

  }
  public initUserGroupConditions() {
    console.log(this.hrCoreData,'HR页面的下拉赋值初始化所有值')
    console.log( this.hrCoreData,'HR右面的生效数据')
    console.log(this.hrCoreData.filter((condition: any) => condition.type == UserGroupConditionType.CONDITION),'HR页面初始化过滤')
    let groupCondition = isArray(this.hrCoreData) ? this.hrCoreData.filter((condition: any) => condition.type == UserGroupConditionType.CONDITION) : [];
    console.log('HrCoreConditions', this.searchForm)
    console.log('HR页面下拉赋值', groupCondition)
    if (groupCondition.length == 1) {
      this.searchForm = groupCondition[0].hrcoreSearch;
      console.log(this.searchForm,'groupCondition==1')
      forEach(this.searchForm, (item, key) => {
        console.log(item,'HR页面初始化Item')
        this.removedDropdownlist[key] = isArray(item) && item.length > 0;
      });
    } else {
      console.log('resetSearch')
      this.resetSearch();
    }
    // this.getDefaultCheckedKeys(this.userGroupCondition, this.postTree);

  }

  private async initEmployeeData() {
    this.loading = true;
    console.log('HrCoreInitEmployeeData', this.type)
    let res = (await this.departmentService.getDepartmentTreeAuthorityManager()) || [];

    if (res && isArray(res)) {
      this.departmentTree = res;
    }

    // 初始化选中的节点
    // this.getDefaultExpandedKeys(this.departmentTree);
    this.getDefaultCheckedKeys(this.hrCoreData, this.departmentTree);
    this.loading = false;
  }

  public async handleCheckChange(data: any, treeStatus: any) {
    console.log('handleCheckChange', data, 'treeStatus', treeStatus);
    console.log(treeStatus.checkedKeys.includes(data.dept_id));
    if (treeStatus.checkedKeys.includes(data.dept_id)) {
      if (this.defaultCheckedKeys.findIndex((item: any) => item === data.dept_id) === -1) {
        this.defaultCheckedKeys.push(data.dept_id);
      }
      if (data.children && data.children.length > 0) {
        // 1. 父节点的所有子节点disabled,
        await this.disabledChildrenOfData(data.children, true);
        // 2. 判断是否在hrCoreData, 如果在，需要从hrCoreData中移除，并且取消该节点的选中
        await this.removeCheckedChildrenOfData(data.children);
        // 3. 计算父节点的所有子节点个数，即生效人数
        await this.getEffectUserNumbers(data);
        let effectUserNumber = this.effectMemebersCount;
        this.effectMemebersCount = 0;
        // 3. 并入hrCoreData
        this.hrCoreData.push(Object.assign({}, {
          dept: { deptCode: data.dept_id, deptName: data.dept_name },
          type: 'dept',
          effectUserNumbers: effectUserNumber
        }));

      } else {
        // 没有子节点的节点直接并入hrCoreData
        await this.getEffectUserNumbers(data);
        let effectUserNumber = this.effectMemebersCount;
        this.hrCoreData.push(Object.assign({}, {
          dept: { deptCode: data.dept_id, deptName: data.dept_name },
          type: 'dept',
          effectUserNumbers: effectUserNumber
        }));
        this.effectMemebersCount = 0;
      }

    } else {
      if (this.defaultCheckedKeys.findIndex((item: any) => item === data.dept_id) !== -1) {
        this.defaultCheckedKeys.splice(this.defaultCheckedKeys.findIndex((item: any) => item === data.dept_id), 1);
      }
      if (this.hrCoreData.findIndex((item: any) => item.type == UserGroupConditionType.DEPT && item.dept.deptCode === data.dept_id) !== -1) {
        // 1. 遍历hrCoreData，从hrCoreData中把取消选中的节点删除
        this.hrCoreData.splice(this.hrCoreData.findIndex((item: any) => item.type == UserGroupConditionType.DEPT && item.dept.deptCode === data.dept_id), 1);
        // 2. 判断取消选中的节点是否有子节点，如果有子节点的话，需要修改子节点的disabled属性
        if (data.children && isArray(data.children) && data.children.length > 0) {
          await this.disabledChildrenOfData(data.children, false);
        }
      }
    }
    this.$emit('changeHrCoreData', this.hrCoreData);
  }

  //默认展开三级
  public getDefaultExpandedKeys(departmentTree: any) {
    let newDefaultExpandedKeys: any = [];
    if (departmentTree && isArray(departmentTree) && departmentTree.length) {
      departmentTree.forEach((tree: any) => {
        if (tree.children && isArray(tree.children) && tree.children.length > 0) {
          tree.children.forEach((childTree: any) => {
            newDefaultExpandedKeys.push(childTree.dept_id);
          });
        } else {
          newDefaultExpandedKeys.push(tree.dept_id);
        }
      });
    }
    this.defaultExpandedKeys = newDefaultExpandedKeys;
  }

  // 初始默认选中的树节点
  public getDefaultCheckedKeys(hrCoreData: any, departmentTree: any) {
    let newDefaultCheckedKeys: any = [], newDefaultExpandedKeys: any = [];
    if (departmentTree && isArray(departmentTree) && departmentTree.length > 0) {
      departmentTree.forEach((tree: any) => {
        if (tree.children && isArray(tree.children) && tree.children.length > 0) {
          tree.children.forEach((childTree: any) => {
            newDefaultExpandedKeys.push(childTree.dept_id);
          });
        } else {
          newDefaultExpandedKeys.push(tree.dept_id);
        }
      });
    }

    if (hrCoreData && isArray(hrCoreData) && hrCoreData.length > 0) {
      hrCoreData.forEach((item: any) => {
        if (item.type === 'dept') {
          if (newDefaultCheckedKeys.findIndex((key: any) => item.type == UserGroupConditionType.DEPT && key === item.dept.deptCode) === -1) {
            newDefaultCheckedKeys.push(item.dept.deptCode);
            newDefaultExpandedKeys.push(item.dept.deptCode);
          }
          // 判断是否有子节点，并且把子节点disabled
          this.getChildrenCheckedKeys(item.dept.deptCode, departmentTree);
        }
      });
    }
    console.log(this.defaultCheckedKeys);
    this.defaultCheckedKeys = newDefaultCheckedKeys;
    this.defaultExpandedKeys = newDefaultExpandedKeys;

  }

  //默认选中节点需要判断是否有子节点，并且把子节点disabled
  // public getChildrenCheckedKeys(deptId: any, itemData: any) {
  //   itemData.forEach((item: any) => {
  //     if (item.dept_id === deptId) {
  //       let children = item.children
  //       if (children && children.length > 0) {
  //         children.forEach((child: any) => {
  //           //this.$set(‘对象名’,要修改的属性名,属性值),这样新添加的属性值会被Vue监听到并且同步渲染到页面上
  //           this.$set(child, 'disabled', true)
  //           if (child.children && child.children.length > 0) {
  //             this.getChildrenCheckedKeysOfNode(child.children)
  //           }
  //         })
  //       }
  //     } else {
  //       let children = item.children
  //       if (children && children.length > 0) {
  //         this.getChildrenCheckedKeys(deptId, children)
  //       }
  //     }
  //   })
  // }

  public getChildrenCheckedKeys(deptId: any, itemData: any) {
    itemData.forEach((item: any) => {
      if (item.dept_id === deptId) {
        let children = item.children;
        if (children && children.length > 0) {
          children.forEach((child: any) => {
            this.$set(child, 'disabled', true);
            let index = this.defaultDisabledDepartmentID.indexOf(child.dept_id);
            if (index == -1) {
              this.defaultDisabledDepartmentID.push(child.dept_id);
            }
            if (child.children && isArray(child.children) && child.children.length > 0) {
              this.getChildrenCheckedKeysOfNode(child.children);
            }
          });
        }
      } else {
        let children = item.children;
        if (children && isArray(children) && children.length > 0) {
          this.getChildrenCheckedKeys(deptId, children);
        }
      }
    });
  }

  public getChildrenCheckedKeysOfNode(children: any) {
    children.forEach((child: any) => {
      this.$set(child, 'disabled', true);
      let index = this.defaultDisabledDepartmentID.indexOf(child.dept_id);
      if (index == -1) {
        this.defaultDisabledDepartmentID.push(child.dept_id);
      }
      if (child.children && isArray(child.children) && child.children.length > 0) {
        this.getChildrenCheckedKeysOfNode(child.children);
      }
    });

  }

  // 获取有效员工数
  public async getEffectUserNumbers(data: any) {
    // let result = await this.departmentService.getMembersOfDepart({ deptId: data.id, divisionCode: ''})
    let result = await this.departmentService.getDepartMembersByID(data.dept_id);
    this.effectMemebersCount = isArray(result) && result.length ? result.length : 0;
    console.log(result);
  }


  public disabledChildrenOfData(children: any, flag: boolean) {
    if (isArray(children) && children.length !== 0) {
      children.forEach((item: any) => {
        this.$set(item, 'disabled', flag);
        if (flag) {
          let index = this.defaultDisabledDepartmentID.indexOf(item.dept_id);
          if (index == -1) {
            this.defaultDisabledDepartmentID.push(item.dept_id);
          }
        } else {
          let index = this.defaultDisabledDepartmentID.indexOf(item.dept_id);
          if (index !== -1) {
            this.defaultDisabledDepartmentID.splice(index, 1);
          }
        }
        if (item.children && isArray(item.children) && item.children.length > 0) {
          this.disabledChildrenOfData(item.children, flag);
        } else {
          this.disabledChildrenOfData([], flag);
        }
      });
    }
  }

  public removeCheckedChildrenOfData(children: any) {
    if (isArray(children) && children.length !== 0) {
      children.forEach((child: any) => {
        let index = this.hrCoreData.findIndex((item: any) => item.type == UserGroupConditionType.DEPT && item.dept.deptCode === child.dept_id);
        if (index !== -1) {
          this.hrCoreData.splice(index, 1);
          if (this.defaultCheckedKeys.findIndex((item: any) => item === child.dept_id) !== -1) {
            this.defaultCheckedKeys.splice(this.defaultCheckedKeys.findIndex((item: any) => item === child.dept_id), 1);
          }
          (this.$refs as any).tree2.setChecked(child, false, true);
        } else {
          if (child.children && isArray(child.children) && child.children.length > 0) {
            this.removeCheckedChildrenOfData(child.children);
          }
        }
      });
    }
  }

  public changeSelectorStyle() {
    this.isShowPostTree = !this.isShowPostTree;
  }

  public handleOptionsChange(isOpen: boolean, propKey: string): void {
    // if (isOpen) {
    //   const target = find(this.searchConfig, (item) => item.prop === propKey);
    //   target.options = this.searchResults[propKey];
    // }
    console.log(!isOpen &&
      isArray(this.searchForm[propKey]) &&
      this.searchForm[propKey].length > 0
    );
    console.log('HR.searchForm',this.searchForm);
    if (
      !isOpen &&
      isArray(this.searchForm[propKey]) &&
      this.searchForm[propKey].length > 0
    ) {
      this.removedDropdownlist[propKey] = true;
       this.getSearchOption();
    }
  }
  // 获取筛选下拉
  private async getSearchOption(val?: Dict<any>): Promise<void> {
    this.loading = true;
    var data_: any = '';
    console.log('HrGetSearchOption', val)
    console.log('HR页面的searchForm', this.searchForm)
    console.log('HR页面的this.Pharma',   this.Pharma)
    console.log('HR页面的type',   this.type)
    // this.searchForm.assistant = this.searchForm.assistant.toString();
    this.searchConfig =
      this.type === 'HrCore' ? hrCoreConfigNew : hrCoreConfigNew;
    this.searchResults = await this.userGroupService.postHrSelect(

    );
    console.log('HR页面所有的下拉',   this.searchResults)
    //user页面的
    // this.searchConfig =
    //   this.type === this.Pharma ? PharmaSearchConfig : OncoSearchConfig;
    // this.searchResults = await this.userGroupService.postcondition(
    //   'pharma',
    //   val ? val : this.searchForm
    // );
    // this.searchResults = await this.userGroupService.postcondition( 'pharma',data_);
    // const keys = Object.keys(res);
    // this.searchConfig.forEach((item: any) => {
    //   item.options = this.searchResults[item.prop];
    // });
    this.startFillSearchConf();
    this.loading = false;
  }
  private startFillSearchConf(): void {
    clearInterval(this.fillIntervalId);
    forEach(this.searchConfig, (item: any) => {
      item.options = [];
    });
    let index = 0;
    this.pushSearchResults(index);
    index += 50;
    this.fillIntervalId = setInterval(() => {
      this.pushSearchResults(index);
      // forEach(that.searchConfig, (item: any) => {
      //   if ((that.searchResults[item.prop] as any[]).length < index) {
      //     item.options.push(...(that.searchResults[item.prop] as any[]).slice(index, index + 50));
      //   }
      // });
      index += 50;
    }, 500);
  }
  private pushSearchResults(index: number): void {
    for (const item of this.searchConfig as any) {
      if ((this.searchResults[item.prop] as any[]).length > index) {
        item.options.push(
          ...(this.searchResults[item.prop] as any[]).slice(index, index + 50)
        );
        // forEach(this.searchForm[item.prop], (val) => {
        //   if (some(item.options, (op) => op.value === val)) {
        //     this.getLabel(item.options, val, item.prop, true);
        //   }
        // });
      } else {
        forEach(this.searchForm[item.prop], (val) => {
          // if (some(item.options, (op) => op.value === val)) {
          this.getLabel(item.options, val, item.prop, true);
          // }
        });
      }
    }
  }
  public handleNoTag(val: string[], propKey: string): void {
    console.log(val,'hr页面的handleNoTagVal')
    console.log(propKey,'hr页面的handleNoTagPropKey')
    if (val[0] == 'Y' || val[0] == 'N') {
      forEach(this.searchForm, (item, key) => {
        if (key == 'assistant') {
          this.searchForm[key] = val[0];
        } else if (key !== 'division') {
          this.searchForm[key] = [];
        }
      });
    } else {
      forEach(this.searchForm, (item, key) => {
        if (key == 'assistant') {
          this.searchForm[key] = '';
        }
      });
    }
    if (isArray(val) && val.length === 0) {
      this.removedDropdownlist[propKey] = false;
      this.getSearchOption();
    }
  }
  public getLabel(
    options: Dict<any>[],
    val: string,
    key: string,
    removeUnexists?: boolean
  ): string | undefined {
    const target = find(options, (e) => e.value === val);
    if (!target) {
      const index = findIndex(this.searchForm[key], (e) => e === val);
      if (index > -1) {
        if (removeUnexists) {
          this.searchForm[key].splice(index, 1);
          console.log('2', this.searchForm)
        }
        if (this.searchForm[key].length === 0) {
          this.removedDropdownlist[key] = false;
        }
      }
      return;
    } else {
      return target.label || target.value;
    }
  }
  public resetSearch(): void {
    forEach(this.searchForm, (item, key) => {
      if (key == 'content') {
        this.searchForm[key] = '';
      }
      else  {
        this.searchForm[key] = [];
      }
    });
     this.removedDropdownlist = {};
    this.getSearchOption();
  }



  public unique(arr: []) {
    return Array.from(new Set(arr));
  }

  public async getMembersOfDepartment(dept: any) {
    this.dialogTableVisible = true;
    this.currentDepartment = dept.deptName;
    let result = await this.departmentService.getDepartMembersByID(dept.dept_id);
    console.log('getMembersOfDepartment', result);
    this.currentDepartMembers = result;

  }

  //生效人数框里删除功能
  public deleteCurrentNodeButton(selectedNode: any) {
    console.log('selectedNode', selectedNode);
    const { deptCode, type, userId } = selectedNode;
    if (type === 'dept' && this.defaultCheckedKeys.findIndex((item: any) => item === deptCode) !== -1) {
      this.defaultCheckedKeys.splice(this.defaultCheckedKeys.findIndex((item: any) => item === deptCode), 1);
    }
    if (type === 'dept' && this.hrCoreData.findIndex((item: any) => item.type == 'dept' && item.dept.deptCode === deptCode) !== -1) {
      this.hrCoreData.splice(this.hrCoreData.findIndex((item: any) => item.type == 'dept' && item.dept.deptCode === deptCode), 1);
      if (this.showDropSearch) {
        if (this.deptCheckList.findIndex((item: any) => item === deptCode) !== -1) {
          this.deptCheckList.splice(this.deptCheckList.findIndex((item: any) => item === deptCode), 1);
        }
      }
      //判断取消选中的节点是否有子节点，如果有子节点的话，需要修改子节点的disabled属性
      this.findAndDeleteCurrentNode(deptCode, this.departmentTree);
      this.updateCheckBoxDisableValue();
    } else if (type === 'user' && this.hrCoreData.findIndex((item: any) => item.type == 'user' && item.user.userId === userId) !== -1) {
      this.hrCoreData.splice(this.hrCoreData.findIndex((item: any) => item.type == 'user' && item.user.userId === userId), 1);
      if (this.showDropSearch) {
        if (this.memberCheckList.findIndex((item: any) => item === userId) !== -1) {
          this.memberCheckList.splice(this.memberCheckList.findIndex((item: any) => item === userId), 1);
        }
      }
    }
  }

  private findAndDeleteCurrentNode(deptCode: any, children: any) {
    if (children && isArray(children) && children.length !== 0) {
      children.forEach((item: any) => {
        if (item.dept_id == deptCode) {
          if (!this.showDropSearch) {
            (this.$refs as any).tree2.setChecked(deptCode, false, true);
          }
          this.disabledChildrenOfData(item.children, false);
        } else {
          this.findAndDeleteCurrentNode(deptCode, item.children);
        }
      });
    }
  }

  private findAndCheckCurrentNode(deptCode: any, children: any) {
    if (children && isArray(children) && children.length !== 0) {
      children.forEach((item: any) => {
        if (item.dept_id == deptCode) {
          if (!this.showDropSearch) {
            (this.$refs as any).tree2.setChecked(deptCode, true, true);
          }
          this.removeCheckedChildrenOfData(item.children);
          this.disabledChildrenOfData(item.children, true);
        } else {
          this.findAndCheckCurrentNode(deptCode, item.children);
        }
      });
    }
  }

  private async queryUserAndDepartByText(): Promise<void> {
    let result = await this.departmentService.searchDepartAndUserByKeywords({ keyword: this.filterText });
    //result为部门输出表deptOutputList和用户输出表userOutputList
    console.log(result);
    if (result.deptOutPutList && isArray(result.deptOutPutList)) {
      this.deptSearchList = result.deptOutPutList;
    }
    if (result.userOutputList && isArray(result.userOutputList)) {
      this.memberSearchList = result.userOutputList;
    }

    // 2. 根据hrCoreData和list来初始化选中的节点，hrCoreData中type=dept的需要在deptSearchList中匹配，type=user的在memberSearchList中匹配
    this.initCheckedListBySearchText(this.deptSearchList, this.memberSearchList, this.hrCoreData);
    this.updateCheckBoxDisableValue();
  }

  public initCheckedListBySearchText(deptList: any, memberList: any, hrCoreData: any) {
    let deptCheckList: any[] = [], memberCheckList: any[] = [];
    hrCoreData.forEach((item: any) => {
      if (item.type === 'dept') {
        let index = deptList.findIndex((dept: any) => dept.deptCode === item.dept.deptCode);
        if (index !== -1) {
          deptCheckList.push(item.dept.deptCode);
        }
      } else {
        let index = memberList.findIndex((member: any) => member.userId === item.user.userId);
        if (index !== -1) {
          memberCheckList.push(item.user.userId);
        }
      }
    });
    this.deptCheckList = deptCheckList;
    this.memberCheckList = memberCheckList;
  }

  public updateCheckBoxDisableValue() {
    this.deptSearchList.forEach((item: any) => {
      let index = this.defaultDisabledDepartmentID.indexOf(item.deptCode);
      if (index !== -1) {
        this.$set(item, 'disabled', true);
      } else {
        this.$set(item, 'disabled', false);
      }
    });
  }

  public changeMemberChecked(memberItem: any) {
    // 判断选中的memberItem是否在checkedlist中，如果不在，则是取消，需要从hrCoreData中删除该条数据；如果在，则加入hrCoreData
    let index = this.memberCheckList.findIndex((item: any) => item === memberItem.userId);
    if (index !== -1) {
      this.hrCoreData.push(Object.assign({}, memberItem, {
        type: 'user',
        effectUserNumbers: 1,
        user: { userId: memberItem.userId, userName: memberItem.fullName }
      }));
    } else {
      this.hrCoreData.splice(this.hrCoreData.findIndex((item: any) => item.type == UserGroupConditionType.USER && item.user.userId === memberItem.userId), 1);
    }
    this.$emit('changeHrCoreData', this.hrCoreData);
  }
  //树形点击事件
  public async changeDeptChecked(dept: any) {
    this.searchLoading = true;
    let index = this.deptCheckList.findIndex((item: any) => item === dept.deptCode);
    if (index !== -1) {
      let result = await this.departmentService.getDepartMembersByID(dept.deptCode);
      console.log(result,'返回值');
      let effectUserNumbersCount = result && isArray(result) && result.length ? result.length : 0;
      this.hrCoreData.push(Object.assign({}, {
        dept: { deptCode: dept.deptCode, deptName: dept.deptName },
        type: 'dept',
        effectUserNumbers: effectUserNumbersCount
      }));
      this.findAndCheckCurrentNode(dept.deptCode, this.departmentTree);
      this.updateCheckBoxDisableValue();
      this.searchLoading = false;
    } else {

      this.hrCoreData.splice(this.hrCoreData.findIndex((item: any) => item.type == UserGroupConditionType.DEPT && item.dept.deptCode === dept.deptCode), 1);
      console.log(this.hrCoreData,'this.hrCoreData')
      console.log(UserGroupConditionType,'UserGroupConditionType')
      this.findAndDeleteCurrentNode(dept.deptCode, this.departmentTree);
      this.updateCheckBoxDisableValue();
      this.searchLoading = false;
    }
    this.$emit('changeHrCoreData', this.hrCoreData);

  }
  // 搜索确定
  public async confirmSearch(): Promise<void> {
    this.loading = true;
    let searchParm = cloneDeep(this.searchForm);
    console.log('确定searchForm', this.searchForm);
    searchParm.content = this.searchForm.content || '';
    console.log('form输入的内容',searchParm);
    //console.log('type', this.type);
    let searchRes = (await this.userGroupService.postUserCount(searchParm));
    console.log(searchRes,'postUserCount返回值')
    let newGroupCondition = Object.assign({}, {
      type: 'condition',
      effectUserNumbers: searchRes && searchRes ? searchRes : 0,
      hrcoreSearch: cloneDeep(this.searchForm)
    });
    console.log('newGroupCondition', newGroupCondition);
    console.log('this.hrCoreData', this.hrCoreData);
    console.log('this.UserGroupConditionType', UserGroupConditionType);
    let groupIndex = this.hrCoreData.findIndex((item: any) => item.type === UserGroupConditionType.CONDITION);
    if (groupIndex === -1) {
      this.hrCoreData.push(newGroupCondition);
    } else {
      this.hrCoreData.splice(groupIndex, 1);
      this.hrCoreData.push(newGroupCondition);
    }

    this.$notify({
      title: '成功',
      message: '筛选添加成功',
      type: 'success'
    });
    this.loading = false;
    this.$emit('changeHrCoreData', this.hrCoreData);
    // this.$emit('change', this.userGroupCondition);
  }

};
