import { Inject } from '@cds/common';
import { HttpService } from '@/services/common/http.service';
import { Notification } from 'element-ui';

export class GroupService {
  @Inject(HttpService) private http!: HttpService;

  // 获取某分组权限用户列表
  public getUsers(param: Dict<any>): Promise<any> {
    return this.http.get('/api/user/group/' + param.groupId + '/users');
  }

  //获取HrCoreUserList
  public getUsersForHrCore(param: Dict<any>): Promise<any> {
    return this.http.get('/api/user/group/' + param.groupId + '/hrCore/users');
  }

  public batchGetUsers(param: Dict<any>): Promise<any> {
    return this.http.post('/api/user/group/userlist', param);
  }


  public batchGetUsersForHrCore(param: Dict<any>): Promise<any> {
    return this.http.post('/api/user/group/hrcore/userlist', param);
  }


  // 保存
  public async saveUserGroupsByApplication(param: Dict<any>): Promise<any> {
    // await this.http.put('/api/user/group/application-relation', param);
    await this.http.post('/api/user/group/application-relation/post', param);
    Notification({
      title: '保存成功',
      message: '',
      type: 'success'
    });
    return;
  }

  // 获取应用分组options
  public getUserGroupsByApplication(param: Dict<any>): Promise<any> {
    return this.http.get('/api/user/group/by-application', param);
  }
 
  //获取选中的分组
  public getUserGroupsByCheck(param: Dict<any>): Promise<any> {
    return this.http.get('/api/UserGroupApplicationRelation/by-application', param);
  }
  //保存选中的分组
  public saveOrUpdate(param: Dict<any>): Promise<any> {
    return this.http.post('/api/UserGroupApplicationRelation/saveOrUpdate', param);
  }

  // 搜索获取应用分组options
  public searchUserGroupsByApplicationKeyword(param: Dict<any>): Promise<any> {
    return this.http.get('/api/user/group/app/group_user/search', param);
  }

  // 获取Field分组options
  public getUserGroups(param: Dict<any>): Promise<any> {
    return this.http.get('/api/user/group/by-type', param);
  }

}
