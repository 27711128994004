


































































































































































import { Component, Ref, Vue } from 'vue-property-decorator';
import { CacheService, Inject } from '@cds/common';
import { DialogService } from '@/services/common/dialog.service';
import { I18nService } from '@cds/i18n';
import { uuid } from '@/services/utils';
import AddManagerUser from '@/components/addManagerUser/addManagerUser.vue';
import { CloseStatus } from '@/services/common/models/dialog.interface';
import {
  PharmaSearchConfig,
  OncoSearchConfig,
  hrCoreConfig,
  rulesConfig
} from '@/components/NovaGroupTransfer/index.config';
import { isArray } from 'lodash';
import { IAdminUser } from '@/views/manager';
import { UserGroupService } from '@/services/user-group-service';
import { ElForm } from 'element-ui/types/form';
import HrCoreUserGroupSelector from '@/components/UserGroup/HrCoreUserGroupSelector.vue';
import NovaUserGroupSelector from '@/components/UserGroup/NovaUserGroupSelector.vue';
import FfUserGroupSelector from '@/components/UserGroup/FfUserGroupSelector.vue';
import { ChannelService } from '@/services/channel-service';
import SelectedUserGroupList from '@/components/UserGroup/SelectedUserGroupList.vue';
import { DivisionOrg } from '@/views/userGroup/config';
import { ElTabs } from 'element-ui/types/tabs';
import { PermissionService } from '@/services/common/permission.service';

@Component({
  components: { SelectedUserGroupList, NovaUserGroupSelector, HrCoreUserGroupSelector,FfUserGroupSelector }
})
export default class ModifyUserGroup extends Vue {
  public activeStep: any = 0;
  public selectedAppList: any = [];
  public authorizedAppList: any = [];
  public allAppList: any = [];
  public fileList: any = [];
  public fileName: string = '';
  private data!: any;//哪里赋值的
  private formLoading: boolean = false;
  public Pharma :any = localStorage.getItem("setup");
  private formData: any = {
    users: [],
    admins: {
      type: 'manual',
      adminInfos:
        [

        ]
    },
    structureFlag: 'Y',
    type: 'hrCoreOrg',
    name: '',
    conditions: {
      hrcore: [],
      pharma: [],
      nonff:[],
      onco: [],
      test:[],
      upload: null
    }
  };
  public rules: any = rulesConfig;
  public config = hrCoreConfig; // HRCore 需要添加的
  private currentUser!: IAdminUser;
  private $dialogInstance!: any;
  private type: any = 'HrCore';
  public rightCheckDataList: any = []
  public rightCheckList: any = []
  public rightdata: any = [];
  private searchParam = {
    page: 1,
    perPage: 10,
    total: 0
  };
  private hasPharmaPermissions: boolean = false;
  private hasHrcorePermissions: boolean = false;
  private hasNonFfPermission: boolean = false;
  // admins


  public activeAdminTab: any = '自定义';
  public adminDivisionList: any = [];
  public divisionList: any = [];
  public salesdivision:any= [];

  @Ref('ruleForm') private ruleForm!: ElForm;


  @Inject(UserGroupService) private userGroupService!: UserGroupService;
  @Inject(ChannelService) private channelService!: ChannelService;
  @Inject(DialogService) private dialog!: DialogService;
  @Inject(I18nService) private i18nSvc!: I18nService;
  @Inject(CacheService) private cacheSvc!: CacheService;
  @Inject(PermissionService) private permissionService!: PermissionService;

  get i18n(): Dict<any> {
    return this.i18nSvc.i18nData;
  }

  get firstStepButtonDisbaled() {
    return this.formData.name == "";
  }

  get activeTabIndex() {
    if (this.hasPharmaPermissions) {
      return '0'
    }
    if (this.hasHrcorePermissions) {
      return '2'
    }
    if (this.hasNonFfPermission) {
      return '1'
    }
    
    return 0
  }

  public async created(): Promise<void> {
    this.currentUser = this.cacheSvc.get('user');
    this.getsetup();
    // this.getAllApplicationList();
    await this.$nextTick();
    // 默认展示第几个tab页

  }

  public async mounted(): Promise<void> {
    this.initFormData();   
    this.checkTabPermissions();
    if (this.data.userGroupId) {
      await this.getUserGroupDetail(this.data.userGroupId)
    }
  }

  public checkTabPermissions() {
    console.log('给tab赋值checkTabPermissions', this.data)
    console.log(this.permissionService.hasPermission)
    this.hasPharmaPermissions = this.permissionService.hasPermission('PharmaGroup') && !this.data.disablePharmaTab;
    this.hasHrcorePermissions = this.permissionService.hasPermission('HrCoreGroup') && !this.data.disableHrcoreTab;
    this.hasNonFfPermission = this.permissionService.hasPermission('NonFfGroup') && !this.data.disableNonffTab;
  }

  

  private async getUserGroupDetail(id: string) {
    try {
      this.formLoading = true;
      let res: any = await this.userGroupService.getHRCoreUsergroupById(id);
      console.log('res1',res)
      this.formData = res;
    } catch {
      this.$notify({
        title: '提示',
        message: '获取分组信息遇到问题，请稍后再试',
        type: 'error'
      });
      this.dialog.close(CloseStatus.close, {}, this.data.key);
    } finally {
      this.formLoading = false;
    }
  }
  //获取配置文件
  public async getsetup(){
    let res: any = await this.userGroupService.getsetup();
    this.salesdivision = res
  }

  public async getAllApplicationList() {
    const param = {
      offset: this.searchParam.page,
      limit: this.searchParam.perPage
    };
    const list = await this.channelService.getChannels(param);
    if (list && list.records && list.records.length > 0) {
      this.allAppList = list.records;
    }
  }

  private initFormData(): void {
    // 初始化筛选条件对象
    // const userGroupRoles: Dict<any> = {};
    // this.config.forEach((item: Dict<any>) => {
    //   userGroupRoles[item.prop] = [];
    // });
    this.formData = {
      users: [],
      admins: {
        type: 'manual',
        adminInfos:
          [
            {
              disabledFlag: 1,
              email: this.currentUser.email,
              employeeId: this.currentUser.employeeId || '',
              fullName: this.currentUser.fullName,
              id: this.currentUser.id
            }
          ]
      },
      structureFlag: 'Y',
      type: 'hrCoreOrg',
      name: '',
      conditions: {
        hrcore: [],
        nonff:[],
        pharma: [],
        onco: [],
        test:[],
        upload: null
      }
    };
  }


  // 添加管理员
  public async addManagerUser(): Promise<void> {
    const key = uuid();
    this.$dialogInstance.hide();
    const res = await this.dialog.open(
      '选择管理员',
      AddManagerUser,
      {
        list: this.formData.admins.adminInfos,
        key
      },
      {
        height: 'auto',
        width: 'auto',
        key
      }
    );
    this.$dialogInstance.show();

    if (res.action === CloseStatus.confirm) {
      this.formData.admins.adminInfos = res.result;
    }
  }

  // 删除标签tag
  public handleTagDelete(obj: any, data: any, key: string): void {
    const index = data.admins.adminInfos.findIndex((item: any) => item[key] === obj[key]);
    data.admins.adminInfos.splice(index, 1);
  }

  public handleUserGroupChange(userGroupChange: any) {
    const { source, type, action, userGroupItem } = userGroupChange;

  }

  public handleChangeRoles() {

  }

  public judgestructureFlag(type: string): void {
    this.formData.structureFlag = type;
  }

  public handleChange(file: any, fileList: any) {
    const isExcel = file.raw.type === "application/vnd.ms-excel";
    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isExcel) {
      this.$message.error("上传文件只能是 excel 格式!");
      return;
    }
    if (!isLt2M) {
      this.$message.error("上传文件大小不能超过 2MB!");
      return;
    }
    if (isExcel && isLt2M) {
      this.fileList = file.raw;
      this.fileName = this.fileList.name;
      fileList.splice(0, 1);
    }
  }

  //  保存
  public async submitSaved(): Promise<void> {
    console.log(this.formData); 
    // 权限分组人员必须填写
    if (await this.ruleForm.validate()) {
      if (
        (this.formData.conditions.pharma && isArray(this.formData.conditions.pharma) && this.formData.conditions.pharma.length > 0) || (this.formData.conditions.hrcore && isArray(this.formData.conditions.hrcore) &&  this.formData.conditions.hrcore.length > 0) || (this.formData.conditions.upload && isArray(this.formData.conditions.upload) && this.formData.conditions.upload.length > 0)|| (this.formData.conditions.nonff && isArray(this.formData.conditions.nonff) && this.formData.conditions.nonff.length > 0)
      ) {
        if (this.formData.id) {
          try {
            this.formLoading = true;
            let updateRes = await this.userGroupService.putUserGroupForHRCore(this.formData);
            // await this.getUserGroupDetail(this.menuActiveIndex);
            this.dialog.close(CloseStatus.confirm, { formData: updateRes }, this.data.key);
            this.$notify({
              title: '成功',
              message: '保存成功',
              type: 'success'
            });
            this.formLoading = false;
          } catch {
            this.$notify({
              title: '提示',
              message: '更新失败，请稍后再试',
              type: 'error'
            });
            this.formLoading = false
          }
        } else {
          await this.submitSaveAS();
        }

      } else {
        this.$notify({
          title: '提示',
          message: '生效人数不能为空',
          type: 'error'
        });
      }
    } else {
      this.$notify({
        title: '提示',
        message: '分组名称不能为空',
        type: 'error'
      });
    }
  }

  // 另存为新的分组
  private async submitSaveAS(): Promise<void> {
    if (await this.ruleForm.validate()) {
      if (
        (this.formData.conditions.pharma && isArray(this.formData.conditions.pharma) && this.formData.conditions.pharma.length > 0) || (this.formData.conditions.hrcore && isArray(this.formData.conditions.hrcore) && this.formData.conditions.hrcore.length > 0) || (this.formData.conditions.upload && isArray(this.formData.conditions.upload) && this.formData.conditions.upload.length > 0)|| (this.formData.conditions.nonff && isArray(this.formData.conditions.nonff) && this.formData.conditions.nonff.length > 0)
      ) {
        try {
          this.formLoading = true;
          this.formData.type = 'hrCoreOrg';
          let createRes = await this.userGroupService.postUserGroupForHRCore(this.formData);
          this.dialog.close(CloseStatus.confirm, { formData: createRes }, this.data.key);
          this.$notify({
            title: '成功',
            message: '保存成功',
            type: 'success'
          });
          this.formLoading = false;
        } catch {
          this.$notify({
            title: '提示',
            message: '保存失败，请稍后再试',
            type: 'error'
          });
          this.formLoading = false;
        }
      } else {
        this.$notify({
          title: '提示',
          message: '生效人数不能为空',
          type: 'error'
        });
      }
    } else {
      this.$notify({
        title: '提示',
        message: '分组名称不能为空',
        type: 'error'
      });
    }
  }

  public submitSavedAndGoAuthorizeAPP(): void {
    this.activeStep = 3;
  }

  public cancelSave(): void {
    this.dialog.close(CloseStatus.close, {}, this.data.key);
  }

  public nextStep(): void {
    if (this.activeStep++ > 1) this.activeStep = 1;
  }

  public lastStep(): void {
    if (this.activeStep == 1) {
      this.activeStep--;
    }
  }
}

