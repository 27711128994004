

















































































































































































































import { Component, Model, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SelectedUserGroupItem extends Vue {

  @Prop({ required: true })
  private source: any;

  @Prop({ required: true })
  private item: any;

  @Prop({ required: true })
  private canDelete: any;


  public async mounted() {

  }

  public deleteItem(data:any){
    console.log('删除的类型',this.source)
    this.$emit('deleteItem', data, this.source);
  }
};
