
































import { Component, Model, Vue, Watch, Prop } from 'vue-property-decorator';
import { Inject } from '@cds/common';
import { UserGroupService } from '@/services/user-group-service';

@Component
export default class UserGroupDetailTable extends Vue {
  private userList: any[] = []; 
  
  @Prop({ required: false })
  private isCustom: any;

  @Prop({ required: false })
  private isBtnShow: any;
    
  @Prop({ required: false })
  private customGroupId: any;

  @Inject(UserGroupService) private userGroupService!: UserGroupService;

  @Model('change', {
    type: Array,
    default: () => []
  })
  public userListInit!: any;

  @Watch('userListInit', { deep: true })
  public handleUserListChange(userListInit: any) {
    this.userList = userListInit;
  }

  public async exportFile() {
    await this.userGroupService.exportFile({customGroupId: this.customGroupId,filename:'自定义组件分组人员名单.xlsx'});
  }

  public created() {
    console.log(this.userList)
    this.userList = this.userListInit;
  }
}
