






































import { Component, Model, Prop, Vue, Watch } from 'vue-property-decorator';
import { CacheService, Inject } from '@cds/common';
import { I18nService } from '@cds/i18n';
import { cloneDeep, isArray } from 'lodash';
import { UserGroupConditionType, UserGroupType } from '@/views/userGroup/config';
import SelectedUserGroupItem from '@/components/UserGroup/SelectedUserGroupItem.vue';
import { PermissionService } from '@/services/common/permission.service';
@Component({
  components: { SelectedUserGroupItem }
})
export default class SelectedUserGroupList extends Vue {
  private allRightDataList: any = {};
  private pharmaData: any = [];
  private hrcoreData: any = [];
  private nonffData: any = [];
  private uploadData: any = [];
  @Prop({ required: true })
  public hasPharmaPermissions!: boolean;
  @Prop({ required: true })
  public hasHrcorePermissions!: boolean;
  @Prop({ required: true })
  public hasNonFfPermission!: boolean;
  @Model('change', {
    type: Object, default: () => {
    }
  })
  public rightDataInit!: any;

  @Inject(I18nService) private i18nSvc!: I18nService;
  @Inject(CacheService) private cacheSvc!: CacheService;
  @Inject(PermissionService) private permissionService!:PermissionService;

  get i18n(): Dict<any> {
    return this.i18nSvc.i18nData;
  }

  get filterEffectUserNumbers() {
    let pharmaCount = 0, oncoCount = 0, hrcoreCount = 0, uploadCount = 0,nonffData=0;
    if (isArray(this.pharmaData) && this.pharmaData.length > 0) {
      pharmaCount = this.pharmaData.reduce(
        (total: number, currentValue: Dict<any>) =>
          total + currentValue.effectUserNumbers,
        0
      );
    }
    if (isArray(this.hrcoreData) && this.hrcoreData.length > 0) {
      hrcoreCount = this.hrcoreData.reduce(
        (total: number, currentValue: Dict<any>) =>
          total + currentValue.effectUserNumbers,
        0
      );
    }
    if (isArray(this.nonffData) && this.nonffData.length > 0) {
      hrcoreCount = this.nonffData.reduce(
        (total: number, currentValue: Dict<any>) =>
          total + currentValue.effectUserNumbers,
        0
      );
    }

    return pharmaCount + oncoCount + hrcoreCount + uploadCount+ nonffData;

  }

  @Watch('rightDataInit', { deep: true })
  public handleinitFormData(value: any): void {
    console.log('106',value)
    this.allRightDataList = cloneDeep(value);
    this.pharmaData = this.allRightDataList.pharma;
    this.hrcoreData = this.allRightDataList.hrcore;
    this.uploadData = this.allRightDataList.upload;
    this.nonffData = this.allRightDataList.nonff;
  }


  public created(): void {
  }

  public mounted(): void {
    this.allRightDataList = cloneDeep(this.rightDataInit);
    this.pharmaData = this.allRightDataList.pharma;
    this.hrcoreData = this.allRightDataList.hrcore;
    this.uploadData = this.allRightDataList.upload;
    this.nonffData = this.allRightDataList.nonff;
  }

  private deleteItem(deletedNode: any, userGroupType: string) {
    console.log('列表里的deleteItem', deletedNode, userGroupType);
    const { type } = deletedNode;
    switch (userGroupType) {
      case UserGroupType.PHARMA:
        if (type == UserGroupConditionType.GROUP){
          let index = this.pharmaData.findIndex((item: any) =>
            item.type === UserGroupConditionType.GROUP
          );
          if (index !== -1) {
            this.pharmaData.splice(index, 1);
          }
        }else if (type == UserGroupConditionType.POST){
          let index = this.pharmaData.findIndex((item: any) =>
            item.type === UserGroupConditionType.POST && item.dept.deptCode === deletedNode.dept.deptCode
          );
          if (index !== -1) {
            this.pharmaData.splice(index, 1);
          }
        }else if (type == UserGroupConditionType.USER){
          let index = this.pharmaData.findIndex((item: any) =>
            item.type === UserGroupConditionType.USER  && item.user.userId === deletedNode.user.userId
          );
          if (index !== -1) {
            this.pharmaData.splice(index, 1);
          }
        }
        break;
      case UserGroupType.HRCORE:
        if (type === 'dept' && this.hrcoreData.findIndex((item: any) => item.type == 'dept' && item.dept.deptCode === deletedNode.dept.deptCode ) !== -1) {
          this.hrcoreData.splice(this.hrcoreData.findIndex((item: any) => item.type == 'dept' &&  item.dept.deptCode === deletedNode.dept.deptCode ), 1);
        } else if (type === 'user' && this.hrcoreData.findIndex((item: any) => item.type == 'user' && item.user.userId === deletedNode.user.userId  ) !== -1) {
          this.hrcoreData.splice(this.hrcoreData.findIndex((item: any) => item.type == 'user' && item.user.userId === deletedNode.user.userId), 1);
        }
        else if (type == UserGroupConditionType.CONDITION){
          let index = this.hrcoreData.findIndex((item: any) =>
            item.type === UserGroupConditionType.CONDITION
          );
          if (index !== -1) {
            this.hrcoreData.splice(index, 1);
          }
        }

        console.log(type,'deleteItem.type')
        break;
      case UserGroupType.UPLOAD:
        break;
      case UserGroupType.NONFF:
        if (type == UserGroupConditionType.GROUP){
          let index = this.nonffData.findIndex((item: any) =>
            item.type === UserGroupConditionType.GROUP
          );
          if (index !== -1) {
            this.nonffData.splice(index, 1);
          }
        }else if (type == UserGroupConditionType.POST){
          let index = this.nonffData.findIndex((item: any) =>
            item.type === UserGroupConditionType.POST && item.dept.deptCode === deletedNode.dept.deptCode
          );
          if (index !== -1) {
            this.nonffData.splice(index, 1);
          }
        }else if (type == UserGroupConditionType.USER){
          let index = this.nonffData.findIndex((item: any) =>
            item.type === UserGroupConditionType.USER  && item.user.userId === deletedNode.user.userId
          );
          if (index !== -1) {
            this.nonffData.splice(index, 1);
          }
        }
        break;
      default:
        break;
    }
    let newRightData = cloneDeep(this.rightDataInit);
    newRightData.hrcore = this.hrcoreData;
    newRightData.pharma = this.pharmaData;
    newRightData.upload = this.uploadData;
    newRightData.nonff = this.nonffData;
    this.$emit('change', newRightData);
    console.log(newRightData)
  }
};
